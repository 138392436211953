.report-purchase-status {
  display: block;
  max-width: 300px;
  width: 100%;
  padding: 8px 16px 24px;
  margin: 10% auto;
  background-color: var(--color-white);
  border-radius: 6px;
  box-shadow: 0px 5px 15px 0px #0000000a;

  &-success {
    background: rgba(10, 122, 0, 0.1);
  }

  &-error {
    background: rgba(196, 15, 17, 0.1);
  }

  &-header {
    display: block;
    padding: 24px 0 0;
    text-align: center;

    &__title {
      display: block;
      margin: 0 0 8px;
      font-weight: 600;
      font-size: 18px;
    }

    &__subtitle {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &-body,
  &-footer {
    display: block;
    height: auto;
    width: auto;
    text-align: center;
  }

  &-body {
    &__icon {
      display: inline-block;
      vertical-align: middle;
      padding: 56px 0 52px;
      font-size: 88px;
      color: var(--color-secondary);

      &--success {
        color: var(--color-success);
      }
    }
  }

  &-footer {
    &__btn {
      display: inline-block;
      vertical-align: middle;
      max-width: 176px;
      width: 100%;
      max-height: 42px;
      height: 100%;
      padding: 8px;
      font-size: 14px;

      &--success,
      &--success:hover,
      &--success:focus {
        color: var(--color-success);
        border-color: var(--color-success);
      }
    }
  }
}
