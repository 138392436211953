.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

// There is no font-weight bold currently for this font!
.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.page-title h1 {
  margin: 0;
}

.color-darkergray {
  color: var(--color-darkergray);
}

.color-error {
  color: var(--color-error);
}

.color-danger {
  color: var(--color-danger);
}

.color-success {
  color: var(--color-success);
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.ant-typography.ant-typography-info {
  color: var(--color-info);
}

h2.ant-typography,
.ant-typography h2 {
  font-weight: 400;
}

.ant-descriptions-item-label {
  font-size: 14px;
}

.ant-descriptions-item-content,
.ant-descriptions-item-label {
  font-weight: 600;
}

a.link-underlined {
  color: var(--color-secondary);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h3.block {
  font-size: 1.125rem;
  font-weight: 600;
  background-color: var(--color-lightergray);
  padding: 1.5rem 2rem;
  border-radius: 5px;
}

h5.header {
  font-weight: 700;
  position: relative;
  font-size: 22px;
  color: var(--color-secondary);
  text-align: left;
}
