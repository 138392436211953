.ant-btn {
  text-transform: uppercase;
  white-space: normal;
}

button.ant-btn-sm {
  height: 30px;
  padding: 0px 36px;
}

button.btn-xl {
  height: 50px;
  padding: 0 20px;
  border-radius: 15px;
  text-transform: none;
  text-align: left;

  .anticon {
    font-size: 22px;
  }

  &:hover {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  &__secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      color: var(--color-white);
    }
  }

  &__secondary-green {
    background-color: var(--color-success);
    border-color: var(--color-success);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-success);
      border-color: var(--color-success);
      color: var(--color-white);
    }
  }
}

.ant-btn-default {
  background-color: transparent;
  border-color: var(--color-darkergray);
}

.ant-btn.btn-secondary {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-white);
}

.ant-btn.btn-success {
  background-color: var(--color-success);
  border-color: var(--color-success);
  color: var(--color-white);
}

.ant-btn.btn-gray {
  background-color: #999;
  border-color: #999;
  color: var(--color-white);
}

.ant-btn.btn-success-bordered,
.ant-btn.btn-success-bordered:hover {
  border: 2px solid var(--color-success);
  color: var(--color-success);
  font-weight: 700;
}

.btn-darkred {
  background-color: var(--color-darkred);
  border-color: var(--color-darkred);
  color: var(--color-white);
  padding: 0 2rem;
}

.btn-action {
  color: var(--color-gray);

  &:focus {
    color: var(--color-gray);
  }

  &--disabled {
    &:hover {
      color: var(--color-gray);
    }
  }

  &--warning {
    opacity: 0.4;
    color: var(--color-secondary);

    svg {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      opacity: 1;
      color: var(--color-secondary);
    }
  }
}

.btn--card-action {
  padding: 0;
  height: 1.5rem;
  border: 0;
}

.icon-btn {
  cursor: pointer;
  height: fit-content;
  transition: 0.2s all;
}

.btn-expand {
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--color-info);
  cursor: pointer;
}
