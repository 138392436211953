.graph-tooltip {
  background-color: var(--color-white);
  padding: 5px;
  border: 2px solid var(--color-black);
  border-radius: 5px;
  max-width: max-content;

  &__content {
    font-size: 12px;
    margin-bottom: 0;
  }

  .ant-tooltip-inner {
    padding: 0.5em 1em;
    min-width: auto;
    box-shadow: none;
  }
}

.recharts-cartesian-axis,
.recharts-label {
  font-size: 14px;
  font-weight: 600;
}

.recharts-default-tooltip {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  border: 0.5px solid #ffffff !important;
  border-radius: 10px;
  line-height: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .recharts-tooltip-label {
    font-weight: 600;
  }

  .recharts-tooltip-item {
    .recharts-tooltip-item-name,
    .recharts-tooltip-item-separator {
      color: #333333 !important;
    }
  }
}

.recharts-yAxis {
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-axis-line {
    display: none;
  }
}

.ratingsHistoryChart {
  .recharts-legend-wrapper {
    left: auto !important;
    right: 10px !important;
  }
}
