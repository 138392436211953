button.ant-switch.toggle {
  margin-right: 20px;
  background-color: #6d7179;
}
button.ant-switch.toggle .ant-switch-handle::after {
  content: none;
}
button.ant-switch.toggle.ownership.ant-switch-checked .ant-switch-handle::before {
  background-color: #ff7900;
}
button.ant-switch.toggle.board.ant-switch-checked .ant-switch-handle::before {
  background-color: #c40f11;
}
button.ant-switch.toggle.founder.ant-switch-checked .ant-switch-handle::before {
  background-color: #ffdf00;
}
button.ant-switch.toggle.management.ant-switch-checked .ant-switch-handle::before {
  background-color: #0d6bfe;
}
button.ant-switch.toggle.joint.ant-switch-checked .ant-switch-handle::before {
  background-color: #c7007b;
}
button.ant-switch.toggle.address.ant-switch-checked .ant-switch-handle::before {
  background-color: #12c800;
}
button.ant-switch.toggle.phone.ant-switch-checked .ant-switch-handle::before {
  background-color: #0c9cb2;
}
button.ant-switch.toggle.email.ant-switch-checked .ant-switch-handle::before {
  background-color: #d904cf;
}
button.ant-switch.toggle.domain.ant-switch-checked .ant-switch-handle::before {
  background-color: #aed100;
}
