button.ant-switch {
  background-color: var(--color-darkergray);
  .ant-switch-handle {
    &:after {
      content: '\2715';
      color: var(--color-darkergray);
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 3px;
      left: 0;
      transition: all 0.2s ease-in-out;
      transform: scale(0.4);
      font-size: 30px;
    }
  }
}

button.ant-switch-checked {
  background-color: var(--color-secondary);
  .ant-switch-handle {
    &:after {
      content: '\2713';
      color: var(--color-secondary);
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 2px;
      left: 0;
      transition: all 0.2s ease-in-out;
      transform: scale(0.4);
      font-size: 30px;
    }
  }
}
