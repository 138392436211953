.ant-card .ant-typography h4 {
  font-weight: 600;
}

.ant-card:hover {
  border-color: var(--color-success);
}

.ant-card-no-hover:hover {
  border-color: transparent;
}

.ant-card .ant-tag {
  margin-right: 0;
  max-height: 24px;
}

.ant-card-actions {
  height: 100%;
  border-top: 1px solid var(--color-lightgray);
  border-radius: var(--border-radius-base);
  display: flex;
}

.ant-card-actions > li {
  margin: 8px;
  align-self: center;
}

.ant-card-actions > li {
  div {
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1rem;
  }
}

.ant-card-actions > li:not(:last-child) {
  border-right: none;
}

.ant-card .ant-card h4 {
  line-height: 1.2;
  margin-bottom: 0.5em;
  margin-top: 1em;
  white-space: normal;
}

.ant-card {
  .ant-card-head {
    min-height: auto;
    cursor: default;
    .ant-card-head-title {
      .edit-card__title {
        line-height: 1.2;
        border-bottom: 2px solid var(--color-lightgray);
        white-space: normal;
        margin-bottom: var(--margin-sm);
        padding-bottom: var(--margin-md);
        color: var(--color-darkergray);
      }
    }
  }

  .ant-list-sm .ant-list-item {
    border-bottom: none;
    padding: 0.25rem 0.75rem;
    line-height: 1.4;
    color: var(--color-darkergray);
  }

  .card-icon {
    position: absolute;
    bottom: -28px;
    right: -16px;
  }
}

$base-card-color: #f0f0f0;

.card-simple {
  &:hover {
    border-color: $base-card-color;
  }
  padding: 0.5rem 0 0 0;

  &-padding-none {
    padding: 0;
  }

  &-body-padding-none {
    .ant-card-body {
      padding-top: 0;
    }
  }

  &-borderless {
    .ant-card-head {
      border: none !important;
    }
  }
}

.card-transparent {
  width: 100%;
  background-color: transparent;
  border-color: var(--color-gray);

  &:hover {
    border-color: var(--color-gray);
  }
}
