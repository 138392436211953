.expanded-basic-status {
  margin: 1.3rem 0;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.ant-table-tbody {
  .ant-table-cell {
    padding: 8px 16px;
  }
}

.differentFinancialYears {
  border-left: 5px solid !important;
}

.ant-table-bordered {
  .ant-table-tbody,
  .ant-table-thead {
    * {
      border-color: rgba(0, 0, 0, 0.06) !important;
    }

    // > tr > th:last-of-type,
    // > tr > td:last-of-type {
    //   border-right-color: transparent !important;
    // }

    // > tr > th:first-of-type,
    // > tr > td:first-of-type {
    //  border-left-color: transparent !important;
    // }

    > tr:nth-child(odd) {
      background-color: #f7f7f7;
    }

    > tr:nth-child(even) {
      background-color: #fff;
    }

    .odd-row {
      background-color: #fff !important;
    }

    .even-row {
      background-color: #f7f7f7 !important;
    }
  }

  .ant-table-thead > tr > th {
    border-bottom: none !important;
  }

  > .ant-table-container {
    border: none !important;
  }
}

.ant-table-cell {
  p {
    margin-bottom: 0.25rem;
  }

  a {
    color: var(--color-info);
  }
}

.ant-table-row {
  &-dark {
    background-color: rgba(0, 0, 0, 20%);

    &.ant-table-row-clickable:hover {
      background-color: rgba(0, 0, 0, 25%);
    }
  }

  &-clickable {
    cursor: pointer;

    &:hover {
      background: var(--color-lightergray);
    }
  }
}

.ant-table {
  .ant-table-cell {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  &--nested {
    margin-left: -1px;

    .ant-table-thead {
      visibility: collapse;
    }

    .ant-table-container {
      margin: 10px 0 !important;
      padding: 0 !important;
    }
  }
}

tr.ant-table-expanded-row > td {
  background-color: var(--color-lightestgray);
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: inherit;
}

.table-headless .ant-table-thead {
  display: none;
}

.table-padded table tr > .ant-table-cell:first-of-type {
  padding-left: 2rem;
}

.ant-table-placeholder:hover > td {
  background-color: unset !important;
}

.other-products-table {
  .ant-pagination-item-link {
    width: 20px;
    height: 20px;
    font-size: 17px;
  }

  .ant-pagination {
    padding: 20px 0 0;
  }

  .ant-pagination-item {
    padding: 0 12px 0 0;
  }

  .ant-pagination-prev {
    padding: 0 12px 0 0;
  }

  .ant-pagination-item-active {
    background: unset;
  }

  .ant-btn {
    border: none;
    text-transform: none;

    span {
      text-decoration: underline;
    }

    &:hover {
      background: unset;
    }
  }

  .ant-table-small {
    td {
      padding: 4px !important;
    }
  }
}

.ant-table-small {
  thead tr th {
    padding: 4px 16px !important;
  }
}
