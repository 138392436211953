.company-credit-limit .company-title {
  display: none;
}
.company-credit-limit .disclaimer {
  background-color: inherit;
  padding: 0;
  font-size: 14px;
  border-radius: 0;
  line-height: 1.25;
}
