.pdf-wrapper {
  padding: 2rem;
  background: #fff;
  width: 21cm;

  .ant-card-bordered {
    border: 2px solid #dfdfdf;
  }

  .ant-btn {
    display: none;
  }

  .ant-card-body {
    background: #f4f4f4;
  }

  .small-blocks {
    .card-simple {
      min-height: 70px;

      .ant-card-body {
        background: #fff;
      }

      .ant-col {
        .value {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0;
          color: #c40f11;
        }

        .title {
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 0;
          color: #999999;
        }
      }
    }
  }

  .ant-empty,
  .disclaimer,
  .expanded-basic-status {
    margin: 0;
  }

  .ant-empty-image {
    height: 15px;
    margin-bottom: 4px;
  }

  .space-vertical-lg {
    margin: 0 auto 0.5rem auto !important;
  }

  .prescore-title {
    padding-top: 0 !important;
  }

  .prescore-chart {
    padding: 4px 0 8px !important;
  }

  .block-header svg,
  .ant-collapse-header svg {
    width: 10px;
  }

  .ant-collapse-content {
    margin: 0 -14px;
  }

  .ant-table-content {
    max-width: 100%;
  }

  .ant-table-wrapper {
    max-width: 100%;
  }

  #reports-table {
    .ant-table-tbody .ant-table-cell {
      padding: 4px 8px !important;
    }

    .ant-table-expanded-row {
      margin: -20px;
    }
  }

  .block-header-span-icon {
    margin-left: 4px;
    width: 100%;
  }

  .block-header-span-no-icon {
    margin-left: 12px;
  }

  .ant-typography,
  .ant-table-cell,
  .ant-col,
  .ant-collapse-header,
  .ant-card-body,
  .ant-empty-description {
    font-size: 10px;
  }

  .bigger-font {
    font-size: 12px;
  }

  .company-credit-limit .disclaimer {
    font-size: 9px;
  }

  .ant-card-body,
  .ant-table-cell {
    padding: 8px;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 8px 12px;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 8px 0;
  }

  .block-header {
    margin-bottom: 4px;
  }

  .pdf-title {
    margin: 0 !important;
    font-size: 16px;
  }

  .pdf-logo-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .pdf-subtitle {
    font-size: 14px;
    font-weight: normal;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .pdf-report-time {
    font-size: 12px;
  }

  .section-title {
    font-size: 12px;
    margin: 0 0 14px 0;
  }

  .individual-section-title {
    font-size: 12px;
    margin: 14px 0 8px 0;
  }

  .recharts-cartesian-axis {
    font-size: 10px;
  }

  .recharts-label-list g text {
    font-size: 8px;
  }

  .ant-row-pdf-gap {
    row-gap: 0.15rem !important;
  }

  .card-statistics {
    .ant-card-body {
      padding: 8px;
    }
  }

  .ant-statistic-content-value {
    font-size: 12px;
  }

  .space-vertical-sm {
    margin: 0.15rem auto !important;
  }

  .login-logo {
    height: 55px;
    width: 164px;
    margin: 0 !important;
  }

  * {
  }

  .ant-card {
    box-shadow: none;
  }

  .ant-row {
    .ant-col-lg-24 {
      width: 100% !important;
      max-width: unset !important;
    }

    .ant-col-lg-12 {
      width: 50%;

      &:last-of-type {
        float: right;
      }
    }
  }

  .company-data-row {
    .ant-col {
      flex: none !important;

      &:first-of-type {
        width: 30% !important;
      }

      &:last-of-type {
        width: 70% !important;
        float: right;
      }
    }

    &-double {
      .ant-col {
        flex: none !important;

        &:nth-of-type(1) {
          width: 50% !important;
        }

        &:nth-of-type(3) {
          width: 10% !important;
        }

        &:nth-of-type(even) {
          width: 50% !important;
          float: right;
        }
      }
    }
  }

  .recharts-wrapper,
  .recharts-surface {
    @media print {
      width: calc(100%) !important;
    }
  }
}

.pdf-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;

  p {
    text-align: center;
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 20px 0 40px;
  }

  .page-break {
    page-break-before: always;
  }

  body {
    background-size: 21cm 29.7cm !important;
  }

  .pdf-wrapper {
    padding: 2rem;

    .prescore-title {
      padding-top: 0 !important;
    }

    .prescore-chart {
      padding: 4px 0 8px !important;
    }

    .ant-btn {
      display: none;
    }

    .ant-collapse-header .anticon {
      display: none !important;
    }

    tbody .ant-table-row-expand-icon {
      display: none;
    }

    .ant-collapse-arrow {
      display: none !important;
    }

    .ant-table-column-sorter-inner {
      display: none !important;
    }

    *::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-pagination {
    display: none;
  }

  .ant-layout {
    background: unset !important;
  }

  .no-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    display: none;
  }

  .ant-table-bordered {
    .ant-table-tbody,
    .ant-table-thead {
      td {
        border-color: #cfcfcf !important;
        border-width: 0 1px 2px 1px !important;
        border-collapse: separate;
        border-style: solid;
      }

      th {
        border-color: #cfcfcf !important;
        border-width: 0 1px 0 1px !important;
        border-collapse: separate;
        border-style: solid;
      }

      > tr > th:last-of-type,
      > tr > td:last-of-type {
        border-right-color: transparent;
      }

      > tr > th:first-of-type,
      > tr > td:first-of-type {
        border-left-color: transparent;
      }
    }

    .ant-table-thead > tr > th {
      border-bottom: none;
    }

    > .ant-table-container {
      border: none;
    }
  }
}
