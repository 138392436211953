.new-debt .subtitle {
  font-size: 13px;
  color: var(--color-icon);
  float: right;
  max-width: 90%;
  text-align: right;
  line-height: 80%;
  margin-top: 5px;
  position: absolute;
  bottom: -16px;
  right: 0;
}

.new-debt button.cancel,
.new-debt button.choose {
  border-color: var(--color-black);
}

.new-debt button.save {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);

  @media (max-width: 767px) {
    width: 100%;
  }
}

.new-debt button.btn-sm-text-left {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
  font-weight: 700;

  @media (max-width: 767px) {
    text-align: center;
    max-width: none;
    width: 100%;
  }

  @media (min-width: 1600px) {
    height: 36px !important;
  }
}

.new-debt button {
  padding-left: 30px;
  padding-right: 30px;
}

.new-debt .ant-row.ant-form-item {
  width: 100%;
}

.new-debt button.choose {
  margin-top: 22px;
}

.new-debt .buttons {
  margin-top: 50px;
}

.new-debt .eur {
  position: absolute;
  font-weight: 600;
  padding: 10px;
}

.new-debt .ant-divider {
  margin: 15px 0 !important;
}

.new-debt label {
  color: var(--color-icon);
}
