.logo {
  background-image: url('../../img/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 2.5rem 1rem 1.5rem 1rem;
  height: 65px;
  background-position-x: center;

  &--collapsed {
    background-image: url('../../img/ci-symbol-on-white.svg');
  }
}

header {
  padding: 0 2rem;

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid transparent;
  }

  .ant-btn {
    text-transform: none;
    padding: 0;

    &--back {
      width: 3rem;
      padding-right: 2rem;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }

  .ant-select {
    width: 80px;

    &-selector {
      font-weight: 600;
    }
  }

  li {
    padding: 0 0.75rem;
  }
}

.notification-bell {
  position: relative;
  width: 3rem;

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 1.4rem;
  }

  &--unread {
    color: var(--color-secondary);
  }
}

.ant-menu-vertical {
  border: none;
}

%menu-item-highlight {
  font-weight: bold;

  span {
    svg {
      color: var(--color-secondary);
    }
  }
}

.ant-layout-sider {
  .ant-menu-vertical {
    display: block;
    font-size: 16px;

    .ant-menu-item {
      transition: all 0.1s ease-in-out;

      &-group {
        &-list {
          li {
            height: auto;
            padding: 8px 24px;
          }
        }
      }

      &:hover {
        @extend %menu-item-highlight;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item-selected {
        @extend %menu-item-highlight;

        border-left: 6px solid var(--color-secondary) !important;
      }

      .space-vertical-md-submenu {
        .ant-menu-item-selected {
          border: none !important;
        }
      }
    }

    .ant-menu-item-selected {
      @extend %menu-item-highlight;

      border-left: 8px solid var(--color-secondary) !important;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid var(--color-secondary);
}

.ant-menu-submenu--no-text {
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      margin-left: 0 !important;
    }
  }
}

.affix-style .ant-affix {
  background-color: var(--color-gray);
}

.ant-menu.tabs,
.ant-menu.sub-tabs {
  background: transparent;

  &--affixed {
    color: var(--color-white);
    border-bottom-color: var(--color-gray);
  }

  .ant-menu-item {
    padding: 0 4px;
    margin: 0 16px;
    border-bottom: 3px solid transparent;

    &-selected {
      border-left: none;
      border-bottom: 3px solid var(--color-secondary);
    }

    &:hover {
      border-bottom: 3px solid var(--color-secondary);
      transition: none;
    }
  }

  .ant-menu-item::after {
    display: none !important;
  }
}

.ant-menu.sub-tabs {
  font-size: 14px;

  .ant-menu-item-active::after,
  .ant-menu-item:hover::after {
    border-width: 2px;
  }
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: var(--color-secondary);
}
