$low: #1dc800;
$average: #ffde51;
$high: #c40f11;

.prescore-report .company-title,
.prescore-report .anticon.anticon-plus {
  display: none;
}

.prescore-report #disclaimer {
  background-color: var(--color-lightergray);
  padding: 20px 15px 20px 15px;
  color: var(--color-darkergray);
}

.prescore-report div.ant-collapse-header {
  font-weight: 600;
}

.prescore-report .prescore-last-salary {
  padding-left: 85px;
  padding-top: 10px;
  font-size: 13px;
}

.credit-risk-graph-container {
  font-weight: 600;

  &__wrapper {
    position: relative;
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -30px;
  }

  @media screen and (max-width: #{$screen-sm}) {
    position: relative;
  }
}

.prescore-chart {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
}

.credit-risk-graph-title {
  position: absolute;
  bottom: 40px;
  left: 51%;
  transform: translateX(-50%);
  font-weight: 600;
  width: 100%;
  text-align: center;

  @media screen and (max-width: #{$screen-sm}) {
    bottom: 20px;
  }

  &__heading {
    font-size: 21px;
    line-height: 1;

    @media screen and (max-width: #{$screen-sm}) {
      font-size: 14px;
    }
  }

  &__risk-level {
    display: block;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;

    @media screen and (max-width: #{$screen-sm}) {
      font-size: 10px;
      line-height: 0.8;
    }
  }

  &--low {
    color: $low;
  }

  &--average {
    color: $average;
  }

  &--high {
    color: $high;
  }
}

.credit-risk-graph__gauge-needle {
  position: absolute;
  top: 33%;
  left: 50%;
  transform-origin: bottom;
  z-index: 2;
  width: 31%;
  height: 31%;

  @media screen and (max-width: #{$screen-sm}) {
    width: 25%;
    height: 25%;
    top: 40%;
  }
}
