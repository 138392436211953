:root {
  --color-secondary: #c40f11;
  --color-white: #ffffff;
  --color-darkerwhite: #dfdfdf;
  --color-black: #000000;
  --color-lightblack: #333333;
  --color-lighterblack: #2d2d20;
  --color-green: #0fa500;

  --background-lightgray: var(--color-lightestgray);
  --background-white: var(--color-white);

  --shadow: 0 8px 16px rgba(143, 145, 165, 0.16);
  --shadow-dark: 0 8px 16px rgba(143, 145, 165, 0.25);
  --shadow-sm: 0px 4px 4px 0px #0000000d;
  --shadow-spiderweb: 5px 10px 7px #0000000d;

  --color-warning: #ff7900;
  --color-success: #0a7a00;
  --color-error: #ef0000;
  --color-error-form: #ff4d4f;
  --color-info: #1996bf;
  --color-icon: #666666;
  --color-icon-warning: #ffde51;

  --color-lightestgray: #f4f4f4;
  --color-lightgray: #f0f0f0;
  --color-lightergray: #e3e4e4;
  --color-gray: #c5c6c7;
  --color-darkergray: #6d7179;
  --color-graybar: #c4c4c4;

  --color-red: #b00000;
  --color-darkred: #990002;

  --border-radius-base: 5px;

  --padding-xxl: 64px;
  --padding-xl: 32px;
  --padding-lg: 24px;
  --padding-md: 16px;
  --padding-sm: 12px;
  --padding-xs: 8px;
  --padding-xss: 4px;

  --margin-lg: 24px;
  --margin-md: 16px;
  --margin-sm: 12px;
  --margin-xs: 8px;
  --margin-xss: 4px;
}
