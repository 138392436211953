@import './helpers.scss';

.login-wrapper {
  min-height: 100vh;

  .small-login-logo {
    height: 75px;
    max-height: 100px;
    margin: 0 -40px;

    @media (min-width: 992px) {
      margin: 0;
    }

    @include phone {
      margin: 0;
    }
  }

  .ant-menu-horizontal {
    border: none;
  }

  @media (min-width: 992px) {
    .ant-btn {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    .ant-btn {
      padding: 0;

      span {
        font-size: 12px;
      }
    }
  }
}

.login-info-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;

  @media (min-width: 992px) {
    width: 100%;
    text-align: left;
  }
}

.login-title {
  color: #c40f11 !important;
  margin-bottom: 8px !important;
}

.login-subtitle {
  min-height: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #999999;
}

.login-image-small {
  height: 35vw;
  margin-right: 10px;

  .login-image {
    width: 100vw;
    left: -60px;
    height: 85%;

    @include phone {
      left: 0;
      width: 100%;
    }
  }
}

.login-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  border-radius: 0 0 0 25px;
  background-position: 25% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../img/loginNew.png');

  // @media (max-width: 991px) {
  //   background-image: url('../img/login-LT-tablet.png');
  // }

  // @media (max-width: 450px) {
  //   background-image: url('../img/login-LT-phone.png');
  // }

  &--backdrop {
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 0 0 0 25px;
    }
  }

  @media (max-width: 991px) {
    background-position: 0% 73%;
    border-radius: 0;
  }
}

.top-text {
  color: white;
  font-size: 28px;
  background: #c40f11;
  width: fit-content;
  padding: 5px 20px;

  @include tablet {
    font-size: 22px;
  }

  @include phone {
    font-size: 16px;
  }
}

.bottom-text {
  color: white;
  font-size: 28px;
  background: #c40f11;
  bottom: 0;
  right: 0;
  position: absolute;
  padding: 5px 20px;
  width: fit-content;

  @include tablet {
    font-size: 22px;
  }

  @include phone {
    font-size: 16px;
  }
}

.login-password-reset-success {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #999999;
}

.login-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  justify-content: space-between;

  @media (max-width: 991px) {
    justify-content: start;
  }

  flex: 1;
  padding: 4rem;
  background-color: var(--color-white);

  @media (max-width: 991px) {
    padding-top: 10px;
  }

  .ant-btn-primary {
    background: var(--color-icon);
    border-color: var(--color-icon);
  }

  .ant-form-item-label > label {
    height: auto;
  }

  .login-block-input {
    border-radius: 15px;
    height: 50px;

    .ant-input-suffix {
      color: #c40f11;
    }

    @media (min-width: 992px) {
      min-width: 300px;
    }
  }

  .login-button-block {
    margin: 0 !important;
    min-width: 300px;
    row-gap: 20px;

    @media (min-width: 992px) {
      max-width: 500px;
    }

    .login-btn {
      width: 100%;
    }
  }

  .login-free-block {
    background: #0a7a001a;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #e2e8f0;
    text-align: center;

    .login-block-input {
      margin-top: 10px;
    }

    @media (min-width: 992px) {
      min-width: 300px;
      width: 100%;
      text-align: left;
    }
  }

  .show-search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--color-secondary);

    &--active {
      color: unset;
    }

    svg,
    svg path {
      transition: 0.3s all;
    }

    svg {
      transform: rotate(270deg);
    }

    &:hover:not(.active) svg path {
      fill: var(--color-secondary) !important;
    }

    &--active {
      svg {
        transform: rotate(360deg);
      }
    }
  }

  @include phone {
    padding: 2rem 10px;
  }
}

.login-logo {
  background-image: url('../img/ci-logo-on-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 1rem 2.5rem 0;
  height: 70px;
  width: 100%;

  @media (max-width: 992px) {
    width: 70%;
  }
}

.login-menu {
  text-transform: uppercase;
}

.login-form-forgot {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #a0aec0;
}

.login-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 2.5rem;
  padding-bottom: 10px;

  .ant-menu-horizontal,
  .ant-menu-item a,
  .ant-menu-submenu {
    border: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    color: #a0aec0;

    .ant-menu-submenu-title:hover,
    .ant-menu-item a:hover,
    .ant-menu-overflow-item:hover,
    .ant-menu-overflow-item::after,
    .ant-menu-submenu-open,
    .ant-menu-item-active {
      color: #a0aec0 !important;
      font-weight: bold !important;
      border: none !important;
      border-bottom: none !important;
    }

    .ant-menu-item {
      border: none !important;
    }
  }
}

.minimized-submenu {
  .ant-menu-vertical.ant-menu-sub {
    min-width: auto;
  }
}

.minimized-submenu-item {
  line-height: 18px !important;
  height: 18px !important;
  font-size: 14px;
  width: auto;
}

.login-search {
  position: relative;

  &.showing {
    animation: antFadeIn 0.4s;
  }

  &.hiding {
    animation: antFadeOut 0.4s;
    visibility: hidden;
  }

  &:hover .anticon-search {
    color: var(--color-icon);
  }

  .anticon-search {
    position: absolute;
    font-size: 1.75rem;
    color: var(--color-gray);
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.2s all;
  }

  input {
    padding-right: 3.5rem;
  }
}

$login-contact-fs: 0.875rem;
$login-contact-bg: var(--background-lightgray);
$login-contact-height: 14.375rem;
$login-contact-icon-size: 1.5rem;

.contact-popover {
  background-color: rgba(255, 255, 255);
  width: calc(100% - 100px);
  padding-bottom: 0;
  left: 50% !important;
  transform: translateX(-50%) !important;
  border-radius: 15px;

  .ant-popover-inner {
    background: transparent;
    border-radius: 15px;
  }

  @media (max-width: 992px) {
    position: absolute;
    top: 10px !important;
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.login-contact {
  padding: 0 0.5rem;
  min-height: $login-contact-height;
  font-size: 12px;

  &-basic {
    text-transform: uppercase;

    strong {
      color: var(--color-secondary);
    }

    .ant-typography {
      margin: 0;

      &.last {
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  &-item {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    align-items: center;

    * {
      margin: 0 !important;
    }
  }

  &-social {
    display: flex;
    gap: 1.5rem;
  }

  > .ant-row {
    padding: 3rem 0;
    height: 100%;
  }

  .login-logo {
    margin-left: auto;
  }

  @include phone {
    padding: 2rem;
    max-height: unset;

    .login-contact-basic {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .login-contact .login-logo {
      margin: 0 auto !important;
    }
  }
}

@include tablet {
  .login-contact .login-logo {
    margin: 0 auto !important;
  }

  .login-contact-basic {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

//TODO: Move somewhere else if needed globally
.ant-form-item-explain {
  font-size: 11px;
}

.login-small-screen-menu {
  position: absolute;
  top: 40px;
  right: 65px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    font-size: 24px;
  }
}

.login-menu {
  background: black;
}
