.marks-explanation .marks-title {
  padding: 15px;
  background-color: #e6e6e6;
  border-radius: 5px;
  font-weight: bold;
  padding-left: 100px;
}

.marks-explanation .marks {
  margin-left: 100px;
  padding: 5px;

  .ant-col {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
}

.marks-explanation .icon--lg {
  height: 22px;
}

.marks-explanation .greyed-out {
  color: #979797;
}
