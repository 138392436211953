.spider-container {
  display: flex;
  width: 100%;
}

.spiderweb {
  display: inline-block;
  width: 80%;
}

.spiderweb .spiderweb-graph svg:not(:first-child) {
  display: none;
}

.detailed-data {
  margin-top: 10px;
  display: inline-block;
}

.detailed-data .detailed-icon {
  padding: 10px;
}

.detailed-data .ant-card-bordered {
  border: none;
}

aside.ant-layout-sider.detailed-data {
  position: absolute;
  right: 0;
  height: 630px;
}

.ant-col.collapse {
  position: absolute;
  top: 290px;
}

.ant-col.details {
  margin-left: 20px;
}

.detailed-data-title {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.spider-web-pdf-download {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  @media (max-width: #{$screen-md}) {
    position: relative;
    float: right;
  }
}

.hierarchy-tree {
  * {
    box-sizing: revert !important;
  }

  .link {
    fill: none;
    stroke: #999;
    stroke-width: 1px;
  }

  .arrow-start {
    marker-start: url('#arrow-start') !important;
  }

  .arrow-end {
    marker-end: url('#arrow-end') !important;
  }

  .arrow {
    fill: #999;
  }

  .node {
    position: relative;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    opacity: 1;
    overflow: visible;
    font-size: 9px;
    box-shadow: 0 4px 4px 0 #99999926;

    .hierarchy-tree__tooltip-icon {
      position: inherit;
      z-index: 1;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    &__icon {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999;
      font-size: 24px;
    }

    &__expandable_conn {
      position: absolute;
      bottom: 20px;
      background: white;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding: 4px 0;
      font-weight: 600;
      color: #777;

      &__count {
        color: #333;
        margin-left: 3px;
      }

      &__btn-position {
        position: absolute;
        top: 50%;
        right: 0;

        &__less {
          position: absolute;
          top: 50%;
          right: 18px;
        }
      }
    }

    &__historical_conn {
      position: absolute;
      bottom: 0;
      background: #f1f1f1;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
      border-radius: 0 0 6px 6px;
      padding: 4px 0;
      font-weight: 600;
      color: #777;

      &__count {
        color: #333;
        margin-left: 3px;
      }

      &__btn-position {
        position: absolute;
        top: 50%;
        right: 0;

        &__less {
          position: absolute;
          top: 50%;
          right: 18px;
        }
      }
    }

    &__expand-collapse-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      margin-right: 4px;

      &:hover {
        border-radius: 50%;
        background: #f4f4f4;
      }
    }

    &__group-item {
      position: relative;
      width: 100%;
      overflow: hidden;
      line-break: normal;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1.25;
      padding: 5px 0;
      border-bottom: 1px solid #f0f0f0;

      &__historical {
        background: #f8f8f8;
      }

      &:first-child {
        padding-top: 18px;
      }

      // Adjust based on elements
      &:nth-last-child(2) {
        border: 0;
      }

      &__heading {
        color: #333333;
        font-weight: 600;
        margin-bottom: 2px;
      }

      &__content {
        width: calc(100% - 8px);
        /* Subtract 8px for 4px margin on each side */
        color: #999999;
        margin: 1px 4px 0;
        font-weight: 600;
        max-width: 80%;

        &__product-search {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
          width: 15px;
          height: 15px;

          &:hover {
            a > svg > circle {
              fill: #f4f4f4;
            }
          }
        }

        &--subtitle {
          text-transform: capitalize;
        }
      }

      &__negative-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;

        &__icon-name {
          width: 10px;
          margin: 2px 1px;
          padding: 2px 1px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__btn-position {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        right: 0;
        bottom: 0;
      }

      .loading-spinner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__line {
      &--share-holder {
        stroke: #c40f11;
      }

      &--executive-relations {
        stroke: #3333ff;
      }

      &--founders {
        stroke: #ffdf00;
      }

      &--board-members {
        stroke: #ff7900;
      }

      &--same-phone-number {
        stroke: #45b0c1;
      }

      &--same-registration-address {
        stroke: #12c800;
      }

      &--same-court-relations {
        stroke: #8c0086;
      }

      &--same-email-relations {
        stroke: #953919;
      }

      &--same-web-relations {
        stroke: #666666;
      }

      &--company {
        stroke: #999999;
      }

      &--person {
        stroke: #999999;
      }
    }
  }

  .loading-spinner {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
  }

  .loading-spinner::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 2px solid #d1d1d1;
    animation: prixClipFix 2s linear infinite;
  }

  &__tooltip {
    position: fixed;
    font-size: 10px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    border-radius: 5px;
    z-index: 9999;
    transform: translateX(-50%);
  }

  &__tooltip-icon {
    position: relative;
    z-index: 9999;

    &:hover {
      cursor: help !important;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}
