.prescore {
  &-chart {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-bordered {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
    }

    &-alt {
      justify-content: center;

      & > div .prescore-text:not(:last-of-type) {
        margin-bottom: 0.75rem;
      }
    }
  }

  &-text {
    &-warning svg {
      color: var(--color-warning);
    }

    &-error svg {
      color: var(--color-error);
    }

    &-ok svg {
      color: var(--color-success);
    }
  }

  &-axis-text {
    position: relative;
    width: 16px;
    height: 16px;
    bottom: 4px;
    color: var(--color-graybar);

    &--low {
      color: var(--color-success);
    }

    &--average {
      color: var(--color-icon-warning);
    }

    &--high {
      color: var(--color-error);
    }
  }

  &-title {
    padding: 20px 0 0 64px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-lightblack);

    &__icon {
      color: var(--color-icon);
      padding-right: 10px;
    }

    &--average {
      color: var(--color-icon-warning);

      & .prescore-title__icon {
        color: var(--color-icon-warning);
      }
    }

    &--low {
      color: var(--color-success);

      & .prescore-title__icon {
        color: var(--color-success);
      }
    }

    &--high {
      color: var(--color-error);

      & .prescore-title__icon {
        color: var(--color-error);
      }
    }

    &--inactive {
      color: var(--color-lightblack);

      & .prescore-title__icon {
        color: var(--color-black);
      }
    }
  }
}

.company-result {
  &-actions {
    display: flex;
    justify-content: space-between;
    padding: 3rem 3rem;

    .ant-btn {
      min-width: 275px;
    }

    @media (max-width: 1250px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }

  &-basic {
    padding: 1.5rem 1rem 3rem;
  }

  &-summary {
    position: relative;

    .ant-btn {
      position: absolute;
      top: 3rem;
      right: 1.6rem;
    }
  }
}

.buy-report {
  &-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;

    .ant-btn {
      width: 90% !important;
      max-width: 300px !important;
    }
  }

  &-select {
    display: flex;
    flex-direction: column;

    .ant-form-item-label {
      text-align: unset;
    }
  }

  &-form form {
    padding: 0 1.5rem 0 0;
  }

  &-checks {
    padding-top: 1rem;

    .ant-checkbox-wrapper {
      padding-left: 0;
    }

    .ant-form-item {
      margin: 0;
    }
  }
}

.payment-options {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 2.25rem 0;

  .payment-option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 4.5rem;
    border: 2px solid var(--color-lightergray);
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s all;

    &-active {
      border-color: var(--color-secondary);
    }

    &:hover {
      box-shadow: var(--shadow);
    }
  }
}
