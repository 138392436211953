.anticon.icon,
svg.icon {
  &--secondary {
    color: var(--color-secondary);
  }
  &--gray {
    color: var(--color-darkergray);
  }
  &--black {
    color: var(--color-black);
  }

  &--lg {
    font-size: 22px;
  }
}

.icon-fill--secondary path {
  fill: var(--color-secondary) !important;
}

.anticon.only-child {
  margin: 0;
}
