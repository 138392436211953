.professional-full-report .ant-menu-overflow.blurred {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.professional-full-report .ant-menu-item:hover,
.professional-full-report .ant-menu-item-selected {
  text-shadow: none;
}

.professional-full-report .ant-menu-overflow.tabs--affixed.blurred {
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

.professional-full-report .clipboard-button {
  position: absolute;
  right: 0;
  bottom: 0;
}

.modal-puzzle-svg {
  background-image: url('../img/customPdfPuzzle.svg');
  position: absolute;
  top: 42%;
  left: 0;
  margin-left: -20px;
  width: 200px;
  height: 160px;
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.customPdf-button {
  text-transform: none !important;
}

.search-blocks {
  .card-simple {
    min-height: 130px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .ant-card-body {
    height: 100%;
    padding: 0;

    .ant-col {
      width: 100%;

      .value {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0;
        color: #c40f11;
      }

      .title {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        color: #999999;
        mix-blend-mode: difference;
      }
    }
  }
}

.small-blocks {
  .card-simple {
    min-height: 90px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .ant-card-body {
    height: 100%;
    padding: 0;

    .icon-negative svg path {
      fill: #c40f11;
    }

    .ant-col {
      width: 100%;

      .value {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0;
        color: #c40f11;
      }

      .title {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        color: #999999;
        mix-blend-mode: difference;
      }
    }
  }

  .negative-class {
    background-color: #facabc;

    .ant-card-body {
      background-color: #facabc !important;
      padding: 0;

      .ant-col {
        width: 100%;

        .value {
          color: #c40f11;
        }
      }
    }
  }
}

.fill-rating-card {
  .ant-card-body {
    height: 100%;
  }
}
