.individual-report {
  .space-vertical-sm {
    margin: 0;
  }

  .protect-identity {
    &__disclaimer {
      margin-top: 16px;
      font-weight: 500;
      line-height: 23px;
      text-align: left;
      color: #999999;
    }

    &__subtitle {
    }
  }
}
