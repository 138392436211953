$bubble-size: 10px;
$spinnin-bubble-translate: 200%;
$spinning-bubble-transform-origin: ($spinnin-bubble-translate - 50%) * -1;

.loading-spinning-bubbles {
  position: fixed;
  top: 50%;
  left: 50%;

  .bubble-container {
    position: absolute;
    top: calc(50% - #{$bubble-size}/ 2);
    left: calc(50% - #{$bubble-size}/ 2);
    transform-origin: $spinning-bubble-transform-origin 50%;

    &:nth-of-type(0n + 1) {
      transform: translateX($spinnin-bubble-translate) rotate(-90deg);
      animation-delay: -1.5s;
    }
    &:nth-of-type(0n + 2) {
      transform: translateX($spinnin-bubble-translate) rotate(-45deg);
      animation-delay: -1.375s;
    }
    &:nth-of-type(0n + 3) {
      transform: translateX($spinnin-bubble-translate);
      animation-delay: -1.25s;
    }
    &:nth-of-type(0n + 4) {
      transform: translateX($spinnin-bubble-translate) rotate(45deg);
      animation-delay: -1.125s;
    }
    &:nth-of-type(0n + 5) {
      transform: translateX($spinnin-bubble-translate) rotate(90deg);
      animation-delay: -1s;
    }
    &:nth-of-type(0n + 6) {
      transform: translateX($spinnin-bubble-translate) rotate(135deg);
      animation-delay: -0.875s;
    }
    &:nth-of-type(0n + 7) {
      transform: translateX($spinnin-bubble-translate) rotate(180deg);
      animation-delay: -0.75s;
    }
    &:nth-of-type(0n + 8) {
      transform: translateX($spinnin-bubble-translate) rotate(225deg);
      animation-delay: -0.625s;
    }

    .bubble {
      background: #fff;
      width: $bubble-size;
      height: $bubble-size;
      border-radius: 50%;
      animation: bubble 1s infinite;
      animation-delay: inherit;
    }
  }
}

@keyframes bubble {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.ant-spin-spinning {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: max-content;
}

.loader-overlay {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
}
