.ant-form-item-label > label {
  font-weight: 600;
}

.ant-form-item {
  &.margin-bottom-none {
    margin-bottom: 0;
  }
}

.checkbox-round .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  border: 1px solid var(--color-darkergray);
}
.checkbox-round .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.ant-card-hoverable:hover .checkbox-round .ant-checkbox,
.ant-card-hoverable:hover .checkbox-round .ant-checkbox-checked::after,
.checkbox-round .ant-checkbox:hover,
.checkbox-round .ant-checkbox-checked::after {
  border-radius: 50%;
  border: 1px solid var(--color-success);
}

.checkbox-round .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--color-success);
}

.ant-checkbox-wrapper {
  display: flex;

  &.required span:nth-of-type(2):before {
    display: inline-block;
    margin-right: 4px;
    color: var(--color-error-form);
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.radio-custom .ant-radio .ant-radio-inner {
  border-radius: 50%;
  border: 1px solid var(--color-darkergray);
}
.radio-custom .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.radio-custom .ant-radio.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-white);
}

.radio-custom .ant-radio:hover .ant-radio-inner {
  border-color: var(--color-success);
}

.form-compact {
  label {
    height: unset;
  }
}

.form-padded {
  label {
    padding-left: 1rem;
  }
}

// This is just stupid but the designer wants it
.ant-input:focus {
  box-shadow: none !important;
}
