.unauthenticated-menu {
  .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  .selectable {
    &:hover {
      background: #fafafa;
    }
  }
}

.unauthenticated-content {
  padding: 10px;
  background: #fff;
  min-height: 100vh;

  .ant-card-body {
    height: 100%;

    button {
      font-size: 14px !important;
      @include phone {
        font-size: 12px !important;
      }
      @include tablet {
        font-size: 12px !important;
      }
    }

    h4,
    h2,
    h5,
    .block-header {
      font-size: 16px !important;
      @include phone {
        font-size: 14px !important;
      }
      @include tablet {
        font-size: 14px !important;
      }
    }
    font-size: 14px !important;
    @include phone {
      font-size: 12px !important;
    }
    @include tablet {
      font-size: 12px !important;
    }
  }

  .search-banner {
    height: 30vw;
    max-height: 300px;

    .login-image {
      border-radius: 0;
    }
  }

  @media (min-width: 992px) {
    padding: 20px;
    background: #fafafa;
  }

  @media (max-width: 991px) {
    .company-title {
      background: rgba(10, 122, 0, 0.1);
      padding: 13px 27px;
      border-radius: 5px;

      h2 {
        font-size: 18px;
        font-weight: 700;
        margin: auto auto auto 10px !important;
      }
    }
  }

  .small-blocks {
    .card-simple {
      .ant-col {
        .value {
          color: #333;
        }

        .title {
          @include phone {
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--color-secondary);
  }
  .content-blur {
    filter: blur(6px);

    //disabling copy for blured content
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    user-select: none;
  }
}

.unauthenticated-contact-us {
  padding-top: 10px;

  strong {
    color: var(--color-secondary);
  }

  .ant-divider-horizontal {
    padding: 8px;
    margin: 0;
  }

  &__icon-paragraph {
    svg {
      margin-right: 5px;
    }
  }
}

.border-free-drawer {
  .ant-menu-inline {
    border-right: none !important;
  }
}

.sticky-navbar {
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #f4f4f4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}

.navbar {
  padding: 10px 10px 0 10px;
}

.ant-layout-sider-children {
  .login-btn {
    font-size: 12px;
    width: 95%;
    height: 35px;
    background-color: var(--color-success);
    border-color: var(--color-success);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-success);
      border-color: var(--color-success);
      color: var(--color-white);
      opacity: 0.9;
    }
  }
}

.single-result__value {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
  color: #999999;
  margin: auto;

  svg {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }
}

.buy-report-card {
  .ant-card-body {
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;

    @include phone {
      height: 100%;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between;
    }
  }
}

.buy-report-checks {
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
}

.buy-report-actions {
  .justify-content-start {
    @include phone {
      justify-content: center;
      margin-bottom: 5px !important;
    }
  }

  .justify-content-end {
    @include phone {
      justify-content: center;
    }
  }

  button {
    width: 90% !important;
    max-width: 300px !important;
  }
}

.buy-report-select {
  .ant-radio-wrapper {
    align-items: center;
    margin-bottom: 8px;
    margin-right: 2rem;
  }

  @include tablet {
    .ant-radio-wrapper {
      font-size: 14px !important;
    }
  }

  @include phone {
    .ant-radio-wrapper {
      font-size: 12px !important;
    }
  }
}

.report-selecting-card {
  .non-active-report {
    .ant-card-body {
      filter: blur(1px);
    }
  }

  .active-report {
    border: solid 1px black !important;
  }

  .ant-card-body {
    @include tablet {
      font-size: 14px;
    }

    @include phone {
      font-size: 12px;
    }
  }

  .professionalReport {
    .ant-card-head {
      background: linear-gradient(180deg, var(--color-success) 49.85%, rgba(255, 255, 255, 0.3) 201.61%);
    }

    .ant-card-body {
      background: #e7f2e6;
    }

    @media (min-width: 1500px) {
      left: 5vw;
    }
  }

  .financialStatement {
    .ant-card-head {
      background: linear-gradient(180deg, #999999 42.11%, rgba(255, 255, 255, 0.5) 191.4%);
    }

    .ant-card-body {
      background: #f1f1f1;
    }

    @media (min-width: 1500px) {
      right: 5vw;
    }
  }

  .ant-card {
    width: 85%;
    max-width: 405px;
    border-radius: 10px;

    .ant-card-head {
      height: 90px;
      color: white;
      text-align: center;
    }

    .type {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.07px;
    }

    .price {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
    }

    .anticon-close {
      margin-right: 10px;
      color: var(--color-secondary);
    }

    .anticon-check {
      margin-right: 10px;
      color: var(--color-success);
    }

    .exclude-value {
      color: #999999;
      line-height: 14px;
    }

    .include-value {
      line-height: 14px;
      font-weight: 500;
    }

    .small-text {
      font-size: 80%;
      color: #999999;
    }
  }
}

.swiper-3d .swiper-slide-shadow {
  background: none !important;

  .swiper-wrapper {
    box-sizing: border-box !important;
  }
}
