@media screen and (max-width: #{$screen-xl}) {
  .code-form .info {
    font-size: 14px !important;
  }
  .code-form .form-checkbox {
    padding-top: 20px;
  }
}
.subject-warning {
  color: var(--color-red);
}
.subject-success {
  color: var(--color-success);
}

.file-upload {
  padding-left: 35px;
}
.ant-upload-list-item-info {
  width: 55%;
}
