// Extra small screen / phone
$screen-xs: 480px;

// Small screen / tablet
$screen-sm: 576px;

// Medium screen / desktop
$screen-md: 768px;

// Large screen / wide desktop
$screen-lg: 992px;

// Extra large screen / full hd
$screen-xl: 1200px;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;

@mixin phone {
  @media (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-xs}) and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg}) and (max-width: #{$screen-xl - 1px}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl}) and (max-width: #{$screen-xxl - 1px}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex !important;
}

.align-items-center {
  display: flex !important;
  align-items: center !important;
}

.align-items-end {
  display: flex !important;
  align-items: flex-end !important;
}

.space-between {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}

.justify-content-center {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.justify-content-end {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
}

.justify-content-start {
  display: flex !important;
  justify-content: flex-start;
  width: 100%;
}

.fd-column {
  flex-direction: column !important;
}

.ant-space {
  margin: 0.75rem auto !important;
}

.space-vertical-none {
  margin: 0 auto !important;
}

.space-vertical-xs {
  margin: 0.5rem auto !important;
}

.space-vertical-sm {
  margin: 1rem auto;
}

.space-vertical-md {
  margin: 1rem auto !important;
}

.space-vertical-lg {
  margin: 0 auto 2rem auto !important;
}

.space-vertical-xl {
  margin: 3rem auto !important;
}

.space-top-none {
  margin-top: 0 !important;
}

.space-top-xsm {
  margin-top: 0.5rem !important;
}

.space-top-md {
  margin-top: 1rem !important;
}

.space-top-lg {
  margin-top: 1.5rem !important;
}

.space-top-xl {
  margin-top: 3rem !important;
}

.space-bottom-none {
  margin-bottom: 0 !important;
}

.space-bottom-xs {
  margin-bottom: 0.5rem !important;
}

.space-bottom-sm {
  margin-bottom: 0.75rem !important;
}

.space-bottom-md {
  margin-bottom: 1rem !important;
}

.space-bottom-lg {
  margin-bottom: 2rem !important;
}

.space-left-md {
  margin-left: 1rem !important;
}

.space-left-sm {
  margin-left: 0.5rem !important;
}

.space-right-sm {
  margin-right: 0.5rem !important;
}

.space-right-md {
  margin-right: 1rem !important;
}

.padding-xxsm {
  padding: 0.25rem !important;
}

.padding-right-xxsm {
  padding-right: 0.25rem !important;
}

.padding-sm {
  padding: 0.75rem !important;
}

.padding-lg {
  padding: 1.5rem !important;
}

.padding-top-lg {
  padding-top: 1.5rem !important;
}

.padding-x-lg {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.padding-none {
  padding: 0 !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-align-center {
  text-align: center !important;
}

.list-center {
  display: inline-block !important;
  text-align: left !important;
  list-style: none !important;
}

.float-right {
  float: right !important;
}

.list-style-none {
  list-style: none !important;
  padding-left: 0 !important;
}

.w-100 {
  width: 100%;
}

.underlined {
  text-decoration: underline !important;
}

.explanation span {
  color: var(--color-error-form);
  margin-right: 0.25rem;
}

.bottom-bordered-row {
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding: 20px;
  border-bottom: 1px solid var(--color-icon);
}

.buy-report {
  float: right;
  margin-top: -30px;
}

.btn-sm-text-left {
  text-align: left;
  padding: 0 12px !important;
  height: 100% !important;
  max-width: 350px;
}

.ant-modal-close-icon {
  font-size: 13px;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: var(--color-info);
  text-decoration: underline;
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.ant-form-item-extra {
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 13px;
}

.section-title {
  font-size: 18px;
  margin: 1.4rem 0;
  font-weight: 500;
}

.disclaimer {
  background: #e6e6e6;
  padding: 1rem;
  font-size: 14px;
  line-height: 1.25;
  border-radius: 5px;
}

.block-header {
  margin-bottom: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  .block-header-span-icon {
    margin-left: 1rem;
    width: 100%;
  }

  .block-header-span-no-icon {
    margin-left: 2rem;
  }

  svg {
    font-size: 20px;
    color: #6d7179;
  }
}

.claims-excel-download {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  @media (max-width: #{$screen-xl}) {
    position: relative;
    float: right;
  }
}

.monitoring-excel-download {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.feedback-modal {
  &__title {
    font-size: 25px;
    font-weight: 600;
    line-height: 21.79px;
    margin-bottom: 1rem;

    .color-success {
      color: var(--color-success);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    height: 2rem;
  }

  &__text-area {
    margin-top: 1rem;
  }
}

.ant-divider-horizontal.non-padding-divider {
  margin: 0 !important;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

#companySearchFilters {
  .ant-form-item-explain-success {
    color: var(--color-error-form);
    align-self: flex-start;
  }
}
