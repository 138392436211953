.main-information #disclaimer {
  background-color: var(--color-lightergray);
  padding: 20px 15px 20px 15px;
  border-radius: 5px;
  color: var(--color-darkergray);
  margin-top: 20px;
}

.main-information.update-contacts label,
.main-information.update-contacts button {
  font-size: 14px;
}

.main-information.update-contacts label {
  padding-left: 20px;
}

.main-information.update-contacts .ant-col.ant-col-24.ant-form-item-label {
  padding: 0px;
  margin-bottom: -5px;
}

.main-information.update-contacts h1 {
  font-size: 24px;
  font-weight: 600;
}

.main-information.update-contacts button {
  font-weight: bold;
}

.main-information a {
  color: #0c9cb2;
}
