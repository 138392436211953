.ant-modal.fullpage-modal {
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;

  .ant-modal-body {
    min-height: 100vh;
    background-color: var(--color-lightestgray);
  }
}

// Inactive session modal
.inactive-session {
  &-actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;

    .ant-btn {
      width: 5rem;
    }
  }

  &-content {
    width: fit-content;
    margin: 0 auto;

    .ant-typography:first-of-type {
      font-size: 1.5rem;
      margin: 1rem 0 0;

      span {
        font-weight: 600;
        color: var(--color-secondary);
      }
    }
  }
}
