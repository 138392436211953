.ant-pagination {
  &-item {
    border: none;
  }

  &-prev,
  &-next {
    .ant-pagination-item-link {
      border: none;
    }
  }
}
