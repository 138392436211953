.ant-list.ant-list-split .list_ul {
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: 0 0;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.ant-list-item {
    border-bottom: none;
    margin-left: 24px;
    line-height: 1;
    margin-bottom: var(--margin-md);

    &:hover {
      background-color: var(--color-lightestgray);
      padding-left: 24px;
      margin-left: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .anticon {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 1;
      text-align: center;
      vertical-align: top;
      margin-left: -24px;
      color: var(--color-success);
    }

    .anticon.anticon-eye-invisible {
      color: var(--color-gray);
    }
  }
}

.ant-list-item {
  del,
  s {
    color: var(--color-gray);
  }
}

.ant-list.grouped-list {
  margin-bottom: 0;

  .ant-list-header {
    padding-bottom: 0;
  }

  .ant-list-header:first-of-type {
    padding-top: 0;
  }

  .ant-list-item {
    margin-left: 2em;
    padding: 0;
  }
}

.ant-list.grouped-list:last-of-type {
  margin-bottom: 1.5em;
}

.ant-list.nested-list {
  margin-left: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}
