@use "sass:math";
$possibilityClasses: (A, A1, A2, A3, B1, B2, B3, C1, C2, C3, D, D1, D2, D3, E1, E2, E3, F);

.possibility {
  &__container {
    height: 15px;
    max-width: 200px;
  }

  &__filler {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      #12c800 11.46%,
      #12c800 21.24%,
      #ff7900 27.08%,
      #ff9b00 47.61%,
      #ffdf00 55.09%,
      #ffdf00 72.86%,
      #ef0000 86.7%,
      #ef0000 100%
    );
    border-radius: inherit;
  }

  &__indicator {
    position: absolute;
    display: inline-block;
    left: -5px;
    top: -10px;
    border: solid var(--color-black);
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(45deg);

    @each $item in $possibilityClasses {
      $i: index($possibilityClasses, $item);

      &--position-#{$item} {
        margin-left: percentage(math.div($i - 0.5, 18));
      }
    }
  }
}
