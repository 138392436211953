.web-monitoring-card .datepicker {
  width: 50%;
}
.web-monitoring-card .search {
  width: 60%;
}
.web-monitoring-card .dropdown-select {
  width: 80%;
}
.web-monitoring-card .ant-divider {
  margin-top: 50px;
}
.web-monitoring-card .column.right-section {
  padding: 10px;
}
.web-monitoring-card .column.left-section {
  padding: 10px 60px 10px 10px;
}
.web-monitoring-card .search-button {
  margin-right: 50px;
}
.web-monitoring-card button {
  width: 150px;
}
.web-monitoring-card .buttons {
  padding-top: 100px;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: var(--color-white);
}
.monitoring-settings a {
  color: var(--color-secondary);
  text-decoration: underline;
}
.monitoring-settings .additional-settings {
  margin-top: 50px;
  margin-bottom: 100px;
}
.monitoring-settings .close {
  margin-left: 15%;
  padding-left: 30px;
  padding-right: 30px;
}
.monitoring-settings th span.ant-checkbox {
  display: none;
}
.monitoring-settings tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: var(--background-lightgray);
}
.ant-checkbox-wrapper {
  margin-left: 50px;
}
.container.monitoring-settings {
  text-align: center;
}
.container.monitoring-settings h1 {
  text-align: left;
}
.settings-table .ant-table-container table thead th {
  border-radius: 0px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-white);
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: var(--color-black) !important;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--color-secondary);
  color: var(--color-white);
  border-radius: 15px;
}
.ant-picker-cell .ant-picker-cell-inner {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 15px;
  color: var(--color-black);
}
