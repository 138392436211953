.success-notification {
  border-left: 10px var(--color-success) solid;
}

.error-notification {
  border-left: 10px var(--color-error) solid;
}

.info-notification {
  border-left: 10px var(--color-info) solid;
}

.warning-notification {
  border-left: 10px var(--color-warning) solid;
}

.ant-notification-notice-close {
  svg {
    font-size: 16px;
  }
}
