.ant-collapse-content > .ant-collapse-content-box {
  padding: 1.25rem 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

.ant-collapse-header svg:hover {
  fill: inherit;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 20px;
  left: 4px;
  top: -8.428px;
}

.ant-collapse {
  border: none;
  background-color: transparent;
}
