.start-report.reports .anticon.anticon-plus {
  display: none;
}

.start-report.reports button.ant-btn,
button.ant-btn.vehicles-button,
button.ant-btn.credit-limit-button {
  border: 2px solid var(--color-icon);
  background-color: transparent;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.credit-limit-disclaimer {
  color: var(--color-black);
  margin-top: 15px;
}

.start-full-report .ant-menu-overflow.blurred {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.start-full-report .ant-menu-item:hover,
.start-full-report .ant-menu-item-selected {
  text-shadow: none;
}

.start-full-report .ant-menu-overflow.tabs--affixed.blurred {
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}
