$step-bg: rgba(var(--color-darkerwhite), 20%);
$step-bg-active: #e6e6e6;

$step-border-c: #979797;
$step-decorator-c: none;
$step-size: 2.625rem;

.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  margin: 0 5rem;

  @include phone {
    margin: 0 1rem;
  }

  @include tablet {
    margin: 0 2rem;
  }

  &-decorator {
    margin-top: calc(#{$step-size} / 2);
    width: 100%;
    height: 1px;
    background-color: $step-decorator-c;
  }

  .step {
    display: flex;
    position: relative;
    top: 0;
    flex-direction: column;

    @media (max-width: 991px) {
      align-items: center;
    }

    &-indicator {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: $step-size;
      height: $step-size;
      background-color: $step-bg;
      border: 2px solid $step-border-c;
      border-radius: 50%;
      transition: 0.2s all;

      &-active {
        border-color: var(--color-success);
        background-color: $step-bg !important;
        color: var(--color-success) !important;

        span {
          width: 30px;
          height: 30px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-complete {
        border-color: var(--color-success);
        color: var(--color-success) !important;
      }
    }

    &-number {
      position: absolute;
      white-space: pre;
      text-transform: uppercase;
      color: $step-border-c;
      bottom: -1.5rem;
      font-size: 12px;

      @include phone {
        font-size: 10px;
      }

      @include tablet {
        font-size: 12px;
      }
    }

    &:hover {
      cursor: pointer;

      .step-indicator {
        background-color: lighten($step-bg-active, 2%);

        &.step-indicator-complete {
          color: initial;
        }
      }
    }

    &-text {
      position: absolute;
      white-space: pre;
      text-transform: uppercase;
      color: $step-border-c;
      bottom: -2.5rem;

      &--complete {
        color: var(--color-success);
      }

      &--active {
        font-weight: 600;
        color: var(--color-success);
      }

      @include phone {
        font-size: 12px;
      }

      @include tablet {
        font-size: 14px;
      }
    }

    &:hover {
      cursor: pointer;

      .step-indicator {
        background-color: lighten($step-bg-active, 2%);

        &.step-indicator-complete {
          color: initial;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    margin: 0 24rem;
  }
}
