.container {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
}

.has-shadow {
  box-shadow: var(--shadow);
}

.has-shadow-spiderweb {
  box-shadow: var(--shadow-spiderweb);
}

.ant-layout-header {
  padding: 0 2rem;
}

.ant-layout-header .ant-menu {
  background-color: var(--color-lightestgray);
}

.ant-layout-sider-trigger {
  margin-bottom: 30px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 80px;

  .ant-layout-sider-trigger {
    bottom: 1rem;
    line-height: normal;
  }
}

.ant-layout-footer {
  text-align: center;
  padding: 0 2rem;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
}

.ant-menu-item {
  border-left: 6px solid transparent;
}

.ant-menu-item-selected {
  font-weight: 600;
  border-left: 6px solid var(--color-secondary);

  &::before,
  &::after {
    display: none;
  }
}

.ant-menu-item-selected,
.ant-menu-item-active {
  svg {
    g {
      path {
        fill: var(--color-secondary) !important;
      }
    }
  }
}

.company-data-row {
  .red {
    color: var(--color-secondary);
  }
}

.space-vertical-md {
  &-submenu {
    display: none;
    padding: 0 0 0 24px;

    &--open {
      display: block;
    }

    .ant-menu-item,
    .ant-menu-item-selected {
      border: none !important;
    }

    .ant-menu-item-group-title {
      display: none;
    }

    .ant-submenu-item {
      a,
      a:hover {
        color: var(--color-lighterblack);
      }

      &::after,
      &::before {
        display: none;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.activeRow {
  background-color: #f4f4f4;
  box-sizing: border-box;
  border-radius: 5px;

  .ant-btn {
    background-color: #f4f4f4;
    color: var(--color-secondary);
    border-color: var(--color-secondary);
  }
}

.ant-layout-sider-children {
  .sider-buttons {
    padding: 0 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button,
    span {
      display: inline-flex;
      align-items: center;
    }

    span {
      margin-left: 10px;
    }

    a:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  button.btn-md {
    height: 36px;
    padding: 0 16px;
    border-radius: 8px;
    border: #999;
    text-transform: none;
    background: #999;
    line-height: 24px;
    width: 100%;
    color: white;
    font-size: 14px;

    &__circle {
      border-radius: 20px;
      height: 40px;
      width: 40px;
      padding: 0 8px;
    }

    &__secondary {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      color: var(--color-white);
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    .sider-buttons {
      padding: 0 0 0 14px;
    }
  }
}
