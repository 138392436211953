.ant-statistic {
  .ant-statistic-content {
    font-weight: 600;
    font-size: 24px;

    @include lg {
      font-size: 20px;
    }
  }
}

.card-statistics {
  .ant-card-body {
    display: grid;
    padding: 30px;

    @include xl {
      min-height: 260px;
    }

    @include xxl {
      min-height: 230px;
    }

    &::before {
      display: none;
    }
  }

  .ant-statistic {
    .ant-statistic-content,
    .ant-statistic-title {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
