.cregister {
  padding-top: 4rem !important;

  label {
    height: unset;

    &::before {
      content: none !important;
    }

    &.ant-form-item-required::after {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  &-actions {
    display: flex;
    padding-top: 3rem;
    justify-content: space-around;

    .ant-btn {
      min-width: 170px;
    }
  }

  &-form {
    position: relative;
    padding: 3rem 4rem;
  }
}

.response-modal {
  position: relative;
  width: 480px !important;

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .response-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-typography:first-of-type {
      font-weight: 600;
      margin: 1rem 0 0.25rem;
    }
  }
}

@include phone {
  .cregister {
    &-form {
      padding: 0;
    }

    &-actions {
      flex-direction: column-reverse;
      justify-content: unset;
      gap: 1rem;

      .ant-btn {
        min-width: 100%;
      }
    }
  }
}
