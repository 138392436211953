.foreign-company-report {
  .sm-padding {
    .ant-card-body {
      padding: 8px !important;

      .anticon svg {
        fill: none !important;
      }
    }
  }

  .card-simple {
    padding: 0 !important;
  }
}

.modal-svg {
  background-image: url('../img/additionalCost.svg');
  position: absolute;
  bottom: -56px;
  left: -20px;
  width: 160px;
  height: 160px;
  //background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
