.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
}

.popup-filler {
  text-align: center;
  position: relative;
  padding: 60px 30px;
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-dark);
}

h1,
p {
  color: var(--color-icon);
}

.popup-filler-close-btn {
  position: absolute;
  top: 10px;
  right: 36px;
}

img {
  padding: 35px;
}

.popup-filler-continue-btn {
  margin-top: 30px;
  bottom: 30px;
  width: 200px;
  padding: 10px;
  font-size: 18px;
  color: var(--color-green);
  font-weight: 600;
  border-radius: 5px;
  background-color: var(--color-white);
  border: 3px solid var(--color-green);
  text-transform: uppercase;
}

.monitoring {
  padding-left: 15px;
  padding-right: 15px;
}
