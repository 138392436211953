.uncovered-debts-table,
.covered-debts-table {
  padding-bottom: 30px;
}
.uncovered-debts-table table td,
.uncovered-debts-table table th,
.covered-debts-table table td,
.covered-debts-table table th {
  text-align: right;
}

.uncovered-debts button.ant-btn.ant-btn-primary,
.covered-debts button.ant-btn.ant-btn-primary {
  background-color: var(--color-red);
  border-color: var(--color-red);
  padding-left: 40px;
  padding-right: 40px;
}

.uncovered-debts-table table thead.ant-table-thead tr th,
.uncovered-debts-table table tbody.ant-table-tbody tr td,
.covered-debts-table table thead.ant-table-thead tr th,
.covered-debts-table table tbody.ant-table-tbody tr td {
  border-color: var(--color-gray) !important;
}
